import Select, { components } from "react-select";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import { InputFieldMessage } from "../input/InputFieldMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {themeStore} from "../../../stores/theme";
import {useTranslation} from "react-i18next";

type Props = {
  control: any;
  name: string;
  label?: string;
  errorText?: boolean;
  options: {
    id?: number;
    value: string | number | ValueType;
    label: string;
  }[];
  classNames?: string;
  defaultValueProp?: any;
  handleChangedValue?: (e: any) => void;
  handleSearchedValue?: (e: string) => void;
  noOptionsText?: string;
  registration?: any;
  type?: "category";
  defaultValue?: any;
  disableClearIndicator?: boolean;
};

type ValueType = {
  label: string;
  value: number;
}[];

export const ReactSelectForm = ({
  control,
  name,
  errorText,
  options,
  label,
  classNames,
  defaultValueProp,
  handleChangedValue,
  handleSearchedValue,
  noOptionsText,
  type,
  defaultValue,
  disableClearIndicator = false
}: Props) => {
  const { showTheme } = themeStore()
  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      border: errorText ? "1px solid red" : 0,
      background: showTheme === "dark" ? "#1b1b1b" : "#f7f7f7",
      padding: 6,
      outline: "none",
      boxShadow: "none",
      borderRadius: "0.5rem",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: showTheme === "dark" ? "white" : "black",
      paddingTop: 0,
      paddingBottom: 0,
      width: 32,
    }),
    groupHeading: () => ({
      fontSize: 16,
      paddingLeft: 5,
      fontWeight: "bold",
    }),
    ClearIndicator: (...props: any) => ({
      ...props,
      cursor: "pointer",
    }),
    singleValue: (styles: any, { isSelected }: any) => ({
      ...styles,
      color: showTheme === "dark" && "white"
    }),
    menu: (styles: any) => ({
      ...styles,
      background: showTheme === "dark" && "#1b1b1b",
      color: showTheme === "dark" && "white"
    }),
    option: (styles: any, { isSelected, isDisabled }: any) => ({
      ...styles,
      backgroundColor: isSelected ? showTheme === "dark" ? "#2F7512" : "#97c940" :"",
      "&:hover": {
        color:  showTheme === "dark" ? "white" : "black",
        opacity: isDisabled ? 0.3 : 0.8,
        cursor: isDisabled ? "default" : "pointer"
      },
      "&:active": {
        background: "none"
      },
      textAlign: isDisabled && "center"
    }),
  };

  const ClearIndicator = (props: any) => {
    const { getStyles } = props;
    return (
      !disableClearIndicator ?
          <div
              style={getStyles("clearIndicator", props)}
              className="cursor-pointer !p-0"
          >
            <div style={{ padding: "0px 5px" }}>
              <FontAwesomeIcon
                  onClick={() => {
                    props.clearValue();
                  }}
                  icon={["fas", "times"]}
                  className=""
              />
            </div>
          </div> : null
    );
  };

  const optionsCategory =
    type &&
    options.map((value) => ({
      label: value.label,
      options: (value?.value as ValueType).map((skill) => ({
        label: skill.label,
        value: skill.value,
      })),
    }));

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          {noOptionsText && noOptionsText}
        </span>
      </components.NoOptionsMessage>
    );
  };
  const {t} = useTranslation();

  return (
    <div className={clsx(errorText ? "" : "mb-7", classNames && classNames)}>
      <label className="text-[18px] text-[#333333] block font-dmSans pb-1 dark:text-white">
        {label}
      </label>
      <Controller
        control={control}
        // defaultValue={options.map((c) => c.value)}
        name={name}
        render={({ field: { onChange, value, ref } }) => {
          return (
            <Select
              styles={customStyles}
              ref={ref}
              defaultValue={ defaultValue && { label: defaultValue, value: value }}
              components={{
                ClearIndicator: (props: any) => ClearIndicator({ ...props }),
                NoOptionsMessage,
              }}
              value={options.find((c: any) => {
                return c.value === value;
              })}
              onChange={(val) => handleChangedValue ? handleChangedValue(val) : onChange(val?.value)}
              isClearable
              onInputChange={(e: any) => {
                handleSearchedValue && handleSearchedValue(e);
              }}
              options={
                options.length === 0 || optionsCategory?.length === 0 ?
                    [{ value: "0", label: "No data", disabled: true }]
                    : type ? optionsCategory : options
              }
              placeholder={t("select")}
              className={clsx("outline-none")}
              isOptionDisabled={(option) => option?.disabled ? true : false}
            />
          );
        }}
      />
      {errorText && <InputFieldMessage />}
    </div>
  );
};
