import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

type Props = {
  defaultPerPage?: number;
};

export const useTable = (props?: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  let currentPage = Number(searchParams.get("page")) || 1;
  let currentPerPage =
    Number(searchParams.get("per_page")) || props?.defaultPerPage || 10;

  let currentSort = searchParams.get("sort");
  let currentDirection = searchParams.get("direction");

  // useEffect(() => {
  //   if (props?.defaultPerPage) {
  //     searchParams.set("per_page", props.defaultPerPage.toString());
  //     setSearchParams(searchParams);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handlePageChange = useCallback((value: number | string) => {
    searchParams.set("page", value.toString());
    setSearchParams(searchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePerPageChange = useCallback((value: number | string) => {
    searchParams.set("per_page", value.toString());
    setSearchParams(searchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = useCallback((field: string, order: string) => {
    searchParams.set("sort", order !== " " ? field : " ");
    searchParams.set("direction", order !== " " ? order : " ");
    setSearchParams(searchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    page: currentPage,
    perPage: currentPerPage,
    handlePageChange,
    handlePerPageChange,
    sort: currentSort,
    direction: currentDirection,
    handleSort,
  };
};
