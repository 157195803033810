import { useNotificationStore } from "stores/notifications";

const useClipboard = () => {
  const { addNotification } = useNotificationStore();

  const copyClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    addNotification({
      message: "Copied to clipboard",
      type: "success",
    });
  };

  return { copyClipboard };
};

export default useClipboard;
