import { nanoid } from "nanoid";
import create from "zustand";

export type NotificationType = {
  id: any;
  type: "info" | "warning" | "success" | "error";
  message: string;
  icon?: string[];
};

type NotificationsStore = {
  notifications: NotificationType[];
  addNotification: (notification: Omit<NotificationType, "id">) => void;
  dismissNotification: (id: string) => void;
};

export const useNotificationStore = create<NotificationsStore>((set) => ({
  notifications: [],
  addNotification: (notification) =>
    set((state) => ({
      notifications: [
        ...state.notifications,
        { id: nanoid(), ...notification },
      ],
    })),
  dismissNotification: (id) =>
    set((state) => ({
      notifications: state.notifications.filter(
        (notification) => notification.id !== id
      ),
    })),
}));
