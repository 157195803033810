import Axios, { AxiosRequestConfig } from "axios";
import storage from "utils/storage";

import { API_URL } from "config";

const authRequestInterceptor = (config: AxiosRequestConfig) => {
  const [token] = storage.getToken();

  if (config?.headers) {
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Headers"] = "*";
    config.headers["Access-Control-Allow-Methods"] =
      "GET,PUT,POST,DELETE,PATCH,OPTIONS";
    config.headers.Accept = "application/json";
    return config;
  }
};

export const axios = Axios.create({
  baseURL: API_URL + "/api",
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    // useNotificationStore.getState().addNotification({
    //   type: 'error',
    //   title: 'Error',
    //   message,
    // });
    console.log(message);

    return Promise.reject(error);
  }
);
