import React from "react";
import clsx from "clsx";
import styled from "styled-components";
import {themeStore} from "../../../stores/theme";

type CardProps = {
  children: React.ReactNode;
  classNames?: string;
};

const Wrapper = styled.div`
  box-shadow: 0px 0px 20px #0000000f;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #0000000f;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
        props.theme === "light" ? "#b7cf8f" : "#2F7512"};
    border-radius: 20px;
    border: ${(props) =>
        props.theme === "light" ? "1px solid #b7cf8f" : ""};
  }
  overflow-x: auto;
`;

export const Card = ({ classNames, children }: CardProps) => {
  const { showTheme } = themeStore()
  return (
    <Wrapper className={clsx("p-7 rounded-3xl bg-white dark:bg-black", classNames)} theme={showTheme}>
      {children}
    </Wrapper>
  );
};
