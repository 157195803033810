import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type FilterTypeOptions = {
  id: string | number;
  name: string;
};

type FilterTagProp = {
  filterType?: FilterTypeOptions[];
  handleSetFilter?: (value: object) => void;
};

export const FilterTag = ({ filterType, handleSetFilter }: FilterTagProp) => {
  return (
    <div className="flex flex-wrap lg:mt-1">
      {filterType &&
        filterType.map((item: FilterTypeOptions) => (
          <span
            key={item.id}
            className="bg-[#f7f7f7] text-gray-500 border border-gray-300 text-[14px] px-2 mr-1 font-dmSans"
          >
            {item.name}
            <FontAwesomeIcon
              onClick={() =>
                handleSetFilter!(
                  filterType.filter((f: FilterTypeOptions) => f.id !== item.id)
                )
              }
              icon={["fas", "xmark"]}
              className="ml-2 hover:cursor-pointer"
            />
          </span>
        ))}
    </div>
  );
};
