import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { API_URL } from "config";
import { axios } from "lib/axios";
import Cookies from 'js-cookie';

const getUser = async() => {
  const response = await axios.get(API_URL+ "/api/portal/me");

  return response.data;
}

const getLanguage = (): string => {
  const user = getUser();

  user.then((res) => {
    const lang = Cookies.get('qerp-language')

    if (!lang) {
      Cookies.set('qerp-language', res.language, { expires: 1 });
    }
  })
  let lang = Cookies.get('qerp-language');

  return lang ?? 'en';
}

i18n.use(i18nBackend).use(initReactI18next).init({
  lng: getLanguage(),
  fallbackLng: getLanguage(),
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: API_URL+ "/api/i18n/{{lng}}.json",
  },
});

export default i18n;