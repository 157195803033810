type ButtonType = {
  text: string;
  type?: "button" | "submit" | "reset";
  isLoading: boolean;
};

export const FormButton = ({ text, type, isLoading = false }: ButtonType) => {
  return (
    <button
      className={`bg-mainColor rounded-md text-white text-[18px] w-3/5 h-12 flex items-center justify-center dark:bg-darkGreen ${isLoading && "opacity-80"}`}
      type={type}
      disabled={isLoading}
    >
      {text}
      {isLoading && <span className="loader"></span>}
    </button>
  );
};
