import Picker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { InputFieldMessage } from "components/elements";
import { Control } from "react-hook-form";

type Props = {
  control: Control<any>;
  name: string;
  label?: string;
  errorText?: string | null;
  classNames?: string;
  dateFormat?: string;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
};
export const DatePickerForm = ({
  control,
  name,
  errorText,
  label,
  classNames,
  dateFormat,
  showYearDropdown = true,
  showMonthDropdown = true,
}: Props) => {
  return (
    <div className={clsx(errorText ? "datepicker-error" : "mb-7", classNames && classNames)}>
      <label className="text-[18px] text-[#333333] block font-dmSans pb-1 dark:text-white">
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref } }) => (
          <Picker
            ref={ref}
            placeholderText="Select date"
            onChange={(date) => onChange(date)}
            selected={value}
            className={clsx("outline-none dark:!bg-darkGray dark:!text-white")}
            // showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            calendarStartDay={1}
            dropdownMode="select"
            dateFormat={dateFormat || "dd.MM.yyy"}
            wrapperClassName={"dark:!bg-darkGray"}
          />
        )}
      />
      {errorText && <InputFieldMessage text={errorText} />}
    </div>
  );
};
