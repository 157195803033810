import Select, { components, Theme } from "react-select";
import {useTable} from "../../../hooks";

type ValueOptions = {
  id: number | string;
  name: string;
}[];

type DataOptions = {
  id: number;
  name: string;
};

type DataItemOptions = {
  id: number | string;
  name: string;
  adress?: string;
  city?: {
    id: number;
    name: string;
    country: {
      id: number;
      name: string;
      code: string;
      phone_code: string;
    };
    state: {
      id: number;
      name: string;
    };
  };
};

type FilterSelectProps = {
  data: DataOptions[];
  placeholder: string;
  handleSelected?: (value: any, type: any) => void;
  filterID?: string;
  value?: ValueOptions;
};
export const FilterSelect = ({
  data,
  placeholder,
  filterID,
  value,
  handleSelected,
}: FilterSelectProps) => {

  const { handlePageChange } = useTable();

  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      marginRight: 40,
      minWidth: 150,
      maxWidth: 300,
    }),
    option: (styles: any, { isSelected }: any) => ({
      ...styles,
      backgroundColor: isSelected && "#97c940",
    }),
  };

  const handleChange = (selected: any) => {
    if (handleSelected) {
      handlePageChange(1);
      handleSelected(selected, filterID);
    }
    handleSelected && handleSelected(selected, filterID);
  };

  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <Select
      styles={customStyles}
      theme={(theme: Theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#97c940",
        },
      })}
      placeholder={placeholder}
      options={data?.map((items: DataItemOptions) => ({
        value: items.name,
        label: items.name,
        id: items.id,
        filter_id: filterID,
      }))}
      value={
        value &&
        value.map((item: DataItemOptions) => ({
          value: item.name,
          label: item.name,
          id: item.id,
          filter_id: filterID,
        }))
      }
      isMulti={true}
      isSearchable={true}
      isClearable={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      onChange={handleChange}
      components={{
        Option,
      }}
      controlShouldRenderValue={false}
      className="w-full"
    />
  );
};
