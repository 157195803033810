const storage = {
  getToken: () => {
    return [
      JSON.parse(window.localStorage.getItem(`qerp_token`) as string),
      JSON.parse(window.localStorage.getItem(`qerp_refresh_token`) as string),
    ];
  },
  setToken: (token: string, refresh_token: string) => {
    if (token) window.localStorage.setItem(`qerp_token`, JSON.stringify(token));
    if (refresh_token)
      window.localStorage.setItem(`qerp_refresh_token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`qerp_token`);
    window.localStorage.removeItem(`qerp_refresh_token`);
  },
};

export default storage;
