import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {InputFieldMessage} from "../input/InputFieldMessage";
import clsx from "clsx";
import {themeStore} from "../../../stores/theme";
import {useTranslation} from "react-i18next";
type Props = {
  isMulti?: boolean;
  value: any;
  onChange: (v: any, l: any) => void;
  options: any[];
  labelName?: string;
  disabled?:boolean;
  error?:boolean;
};

export const OptionsOutsideSelect = ({
  isMulti,
  options,
  value,
  onChange,
  labelName,
  disabled,
  error
}: Props) => {

  const { showTheme } = themeStore()
  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      background: showTheme === "dark" && "#1b1b1b",
      backgroundColor: "#f7f7f7",
      padding: 6,
      outline: "none",
      boxShadow: "none",
      borderRadius: "0.5rem",
      border: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: showTheme === "dark" ? "white" : "black",
      paddingTop: 0,
      paddingBottom: 0,
      width: 32,
    }),
    groupHeading: () => ({
      fontSize: 16,
      paddingLeft: 5,
      fontWeight: "bold",
    }),
    singleValue: (styles: any, { isSelected }: any) => ({
      ...styles,
      color: showTheme === "dark" && "white"
    }),
    menu: (styles: any) => ({
      ...styles,
      background: showTheme === "dark" && "#1b1b1b",
      color: showTheme === "dark" && "white"
    }),
    option: (styles: any, { isSelected }: any) => ({
      ...styles,
      backgroundColor: isSelected && "#2F7512",
      "&:hover": {
        color:  showTheme === "dark" && "white",
        background: showTheme === "dark" && "#2F7512"
      },
    }),
  };
  
  const handleRemoveValue = (valueName: any) => {
    if (!onChange) return;

    const removedValue = value.find((val: any) => val.value === valueName);
    if (!removedValue) return;
    onChange(
      value.filter((val: any) => val.value !== valueName),
      { name: valueName, action: "remove-value", removedValue }
    );
  };

  const {t} = useTranslation();

  return (
    <div className={"px-4"}>
      {labelName &&
        <label className="text-[18px] text-[#333333] block font-dmSans pb-1">
          {t(labelName)}
        </label>
      }
      <Select
        styles={customStyles}
        options={options}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        controlShouldRenderValue={!isMulti}
        isOptionDisabled={(option) => disabled || false}
        className={clsx(`${error && "border border-rose-500 rounded-lg"}`)}
        placeholder={t('select')}
      />
      {error && <InputFieldMessage text={t("Please select location")} />}
        {isMulti && value?.length
          ? value.map((val: any) => (
            <div className="">
              <span
                key={val.value}
                className="bg-[#f7f7f7] text-mainColor border border-mainColor text-[14px] px-2 mr-1 font-dmSans p-0"
              >
                {val.label}
                <FontAwesomeIcon
                  name={val.value}
                  onClick={() => handleRemoveValue(val.value)}
                  icon={["fas", "xmark"]}
                  className="ml-2 hover:cursor-pointer"
                />
              </span>
            </div>
            ))
          :
            null}
    </div>
  );
};
