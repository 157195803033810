import { useNotificationStore } from "stores/notifications";
import styled from "styled-components";
import { Notification } from "./Notification";

const Wrapper = styled.div`
  transition: all 2s;
  z-index: 260;
`;

export const Notifications = () => {
  const { notifications, dismissNotification } = useNotificationStore();

  return (
    <Wrapper className={`fixed left-0 top-0 w-full`}>
      {notifications.map((item) => (
        <Notification
          key={item.id}
          data={item}
          handleRemove={(id: any) => dismissNotification(id)}
        />
      ))}
    </Wrapper>
  );
};
