import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { lazyImport } from "utils/lazyImport";
import { Auth } from "features/authentication";
import { useAuth } from "stores/auth";
import { useQueryParams } from "utils/queryParams";
import storage from "utils/storage";
import { useUpdateUser } from "features/authentication/api/getUser";
import {themeStore} from "../stores/theme";

const { Protected } = lazyImport(() => import("./Protected"), "Protected");

export const AppRoutes = () => {
  const { showTheme } = themeStore()
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { updateUser } = useUpdateUser();

  useEffect(() => {
    updateUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let token = queryParams.get("token");
    if (token) {
      storage.setToken(token, "");
      updateUser();
    }
  }, [queryParams, updateUser, navigate]);

  useEffect(() => {
    document.body.className = showTheme
  }, [showTheme]);

  return (
    <Routes>
      {user.email ? (
        <Route path="/*" element={<Protected />} />
      ) : (
        <Route path="/*" element={<Auth />} />
      )}
    </Routes>
  );
};
