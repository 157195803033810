import { useState } from "react";
import clsx from "clsx";
import { UseFormRegisterReturn } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useClipboard from "hooks/useClipboard";

type InputProps = {
  labelName?: string;
  inputType: string;
  className?: string;
  registration?: Partial<UseFormRegisterReturn>;
  error?: boolean;
  name?: string;
  hidden?: boolean;
  readOnly?: boolean;
  placeHolder?: string;
  value?: string | number;
  checked?: boolean;
  copied?: boolean;
  numberStep?: string | number;
};

export const InputField = ({
  labelName,
  inputType,
  className,
  registration,
  error,
  name,
  hidden,
  readOnly,
  placeHolder,
  numberStep,
  value,
  checked,
  copied,
}: InputProps) => {
  const { copyClipboard } = useClipboard();
  const [passwordShow, setPasswordShow] = useState(false);

  return (
    <>
      {labelName && (
        <label className="text-[18px] text-[#333333] block font-dmSans pb-1 dark:text-white">
          {labelName}
        </label>
      )}
      <div className={clsx(`flex flex-row relative ${copied && "w-72"}`)}>
        <input
          type={
            inputType === "password"
              ? passwordShow
                ? "text"
                : "password"
              : inputType
          }
          placeholder={placeHolder}
          value={value && value}
          checked={checked}
          name={name && name}
          hidden={hidden && hidden}
          className={clsx(
            `rounded-lg leading-[34px] bg-[#f7f7f7] focus:outline-none w-full p-2 dark:text-white dark:bg-darkGray ${
              !error
                ? "mb-6 border border-transparent dark:border-none"
                : "border border-rose-500"
            } ${copied && "mb-0"}`,
            className
          )}
          {...registration}
          readOnly={readOnly}
          {...(numberStep && { step: numberStep })}
        />
        {inputType === "password" && (
          <FontAwesomeIcon
            className="absolute right-4 top-4 text-[#878787] hover:cursor-pointer"
            icon={["fas", passwordShow ? "eye-slash" : "eye"]}
            onClick={() => setPasswordShow(!passwordShow)}
          />
        )}
        {copied && (
          <FontAwesomeIcon
            icon={["fas", "clipboard"]}
            className="absolute right-11 top-4 text-[#878787] hover:cursor-pointer"
            onClick={() => copyClipboard(value as string)}
            title="Copy to Clipboard"
          />
        )}
      </div>
    </>
  );
};
