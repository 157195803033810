import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ButtonType = {
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  variant?: "primary" | "success" | "error" | "warning";
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  deleteIcon?: boolean;
  editIcon?: boolean;
  viewIcon?: boolean;
  downloadIcon?: boolean;
  customIcon?: any;
  spinner?: boolean;
};

const variants = {
  primary: "bg-mainColor border-transparent dark:bg-darkGreen",
  success: "bg-mainGreen border-transparent dark:bg-darkGreen",
  warning: "bg-mainYellow dark:bg-darkYellow border-transparent",
  error: "bg-mainRed dark:bg-darkRed border-transparent",
};

export const Button = ({
  children,
  type,
  variant,
  className,
  deleteIcon,
  disabled,
  editIcon,
  viewIcon,
  onClick,
  downloadIcon,
  customIcon,
  spinner = false,
}: ButtonType) => {
  return (
    <button
      disabled={disabled && disabled}
      onClick={onClick || undefined}
      type={type || "button"}
      className={clsx(
        `rounded-md text-white text-[14px] px-4 py-[0.4rem] mr-2.5`,
        variant ? `${variants[variant]}` : "bg-mainColor",
        disabled && `opacity-80`,
        className && className
      )}
    >
      {deleteIcon || editIcon || viewIcon || downloadIcon || customIcon ? (
        <FontAwesomeIcon
          className="w-4"
          icon={
            customIcon
              ? customIcon
              : deleteIcon
              ? ["fas", "trash"]
              : editIcon
              ? ["fas", "pen"]
              : viewIcon
              ? ["fas", "eye"]
              : downloadIcon
              ? ["fas", "download"]
              : ["fas", "trash"]
          }
        />
      ) : (
        children
      )}
      {spinner && (
        <span className="loader !ml-[8px] !w-[11px] !h-[11px] !border-[2px]"></span>
      )}
    </button>
  );
};
