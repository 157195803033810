import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { queryClient } from "lib/react-query";
import { QueryClientProvider } from "react-query";

import { ErrorBoundary } from "react-error-boundary";
// import { ReactQueryDevtools } from "react-query/devtools";
import { Button } from "components/elements";
// import { useProgressBar } from "stores/progressBar";
// import { ProgressBar } from "components/progressBar/ProgressBar";
import { Notifications } from "components/notifications/Notifications";

type ProviderProps = {
  children: React.ReactNode;
};

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button
        className="mt-4"
        onClick={() => window.location.assign(window.location.origin)}
      >
        Refresh
      </Button>
    </div>
  );
};

const errorHandler = (error: Error) => {
  console.log("from error boundary: ", error);
};

export const AppProvider = ({ children }: ProviderProps) => {
  // const { progress } = useProgressBar();

  return (
    <React.Suspense fallback={null}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools /> */}
          <Router>{children}</Router>
        </QueryClientProvider>
      </ErrorBoundary>
      {/* {progress ? <ProgressBar /> : null} */}
      <Notifications />
    </React.Suspense>
  );
};
