import clsx from "clsx";

type SelectType = {
  labelName?: string;
  className?: string;
  error?: boolean;
  options: { value: string | number; text: string | number }[];
  selected?: boolean;
  onChange: (value: string) => void;
  selectedOption?:any
};

export const Select = ({
  labelName,
  className,
  options,
  error,
  selected,
  selectedOption,
  onChange,
}: SelectType) => {
  return (
    <>
      {labelName && (
        <label className="text-[18px] text-[#333333] block font-dmSans pb-1">
          {labelName}
        </label>
      )}
      <select
        className={clsx(
          `rounded-lg leading-[34px] bg-[#f7f7f7] focus:outline-none w-full p-2 py-4  ${
            !error && !selected
              ? "mb-6 border border-white"
              : error && !selected
              ? "border border-rose-500"
              : selected && "border border-white"
          }`,
          className && className
        )}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((item) => (
          <option key={item.value} value={item.value} selected={item.value === selectedOption && selectedOption}>
            {item.text}
          </option>
        ))}
      </select>
    </>
  );
};
