import create from "zustand";
import { persist, devtools } from "zustand/middleware";

type UserTypes = {
  id?: number | string;
  email: string;
  profile_photo?: string;
  first_name?: string;
  last_name: string;
  phone_number: string;
  birthday: string | Date;
  office?: any;
  job_type?: any;
  available_sick_leave_days?: number | string;
  old_available_vacation_days?: number | string;
  new_available_vacation_days?: number | string;
  start_matrix?: number;
  is_lead?:boolean;
  show_matrix?:boolean | number;
};

type AuthOptions = {
  user: UserTypes;
  setUser: (user: UserTypes) => void;
  clearUser: () => void;
  updateStartMatrix: (matrix:number) => void;
};

export const useAuth = create<AuthOptions>()(
  devtools(
    persist(
      (set) => ({
        user: {} as UserTypes,
        setUser: (values) =>
          set(() => ({
            user: values,
          })),
        clearUser: () =>
          set(() => ({
            user: {} as UserTypes,
          })),
          updateStartMatrix: (matrix) =>
              set((prevState:any) => ({
                  user: {...prevState.user, start_matrix: matrix}
              })),
      }),
      {
        name: "qerp_",
        getStorage: () => localStorage,
      }
    )
  )
);
